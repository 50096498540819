/**
 * @deprecated Use `BaseInput` instead.
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { type ReactNode } from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Tooltips from './../../atoms/Tooltip/Tooltips';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  value?: string | number;
  view?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'form'
    | 'inline'
    | 'inputForm'
    | 'search'
    | 'editForm'
    | 'linkGenerator'
    | 'extension'
    | 'deepLink';
  label?: string | ReactNode;
  placeholder?: string;
  name?: string;
  lockValue?: boolean;
  type: 'text' | 'email' | 'password' | 'number' | 'time' | 'file' | 'url';
  required?: boolean;
  optional?: boolean;
  resourceLink?: React.ReactElement<HTMLInputElement>;
  inputArgs?: object;
  labelSpacedVariant?: boolean;
  noLabel?: boolean;
}

const primary = {
  fontSize: '13px',
  fontFamily: '$body',
  fontWeight: '$bold',
  input: {
    height: '44px',
    fontSize: '$size16',
    lineHeight: 1.37,
    fontWeight: '$regular',
    border: '1px solid $blue7',
    borderRadius: '1px',
    '&:focus': {
      outline: 'none',
    },
  },
  '@bp5': {
    fontSize: '15px',
  },
};

const focus = {
  '&:focus': {
    borderColor: '$blue',
    outline: 0,
    boxShadow:
      'inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6)',
  },
};

const secondary = {
  fontSize: '$size16',
  fontWeight: '$bold',
  input: {
    fontSize: '$size16',
    fontWeight: '$regular',
    border: '1px solid $lightgray1',
    borderRadius: '2px',
    ...focus,
  },
};

export const styleInputForm = {
  input: {
    maxWidth: '360px',
    height: '44px',
    border: '1px solid $blue7',
    borderRadius: '1px',
    lineHeight: 1.42,
    letterSpacing: '-0.2px',
    fontSize: '12px',
    fontFamily: '$label',
    fontWeight: '$regular',
    padding: '6px 12px',
    ...focus,
  },
};

const inline = {
  ...primary,
  input: {
    marginLeft: 0,
    maxWidth: '100%',
    '@bp3': {
      marginLeft: '12px',
      maxWidth: 'calc(100% - 12px)',
    },
  },
  '@bp5': {
    display: 'grid',
    gridTemplateColumns: '4fr 7fr',
    alignItems: 'flex-start',
    marginTop: '18px',
    input: {
      marginLeft: '3px',
      marginRight: '-3px',
    },
  },
  '@bp6': {
    input: {
      marginLeft: '-3px',
      marginRight: '-3px',
    },
  },
};

const Container = styled('label', {
  display: 'block',
  fontFamily: '$label',
  width: '100%',
  variants: {
    view: {
      primary,
      extension: {
        ...primary,
        input: {
          ...primary.input,
          borderColor: '$darkgray1',
          marginTop: '7px',
        },
      },
      secondary,
      inline,
      tertiary: {
        ...secondary,
        margin: '15px 0',
        input: {
          ...secondary.input,
          fontSize: '12px',
        },
      },
      secondaryRegular: {
        ...secondary,
        fontWeight: '$regular',
        fontSize: '$size14',
        input: {
          ...secondary.input,
          fontSize: '12px',
        },
      },
      editForm: {
        ...secondary,
        marginBottom: '20px',
        input: {
          ...secondary.input,
          border: '1px solid #cfdadc',
          fontSize: '12px',
        },
      },
      form: {
        marginBottom: '14px',
        fontSize: '$size16',
        fontFamily: '$body',
        fontWeight: '$bold',
        ...styleInputForm,
      },
      inputForm: {
        fontSize: '$size18',
        fontFamily: '$body',
        fontWeight: '$bold',
        '&>span': {
          marginBottom: '9px',
        },
        input: {
          padding: '0 15px',
          height: '38px',
          border: '1px solid $gray3',
          fontFamily: '$label',
          fontSize: '$size16',
          color: '#0e2d43',
          ...focus,
        },
      },
      search: {
        ...primary,
        input: {
          ...primary.input,
          height: '38px',
          padding: '0 6px',
        },
      },
      linkGenerator: {
        ...primary,
        fontSize: '13px',
        fontFamily: '$body',
        fontWeight: '$bold',
        input: {
          ...primary.input,
          height: '34px',
          marginLeft: 0,
          maxWidth: '100%',
          '@bp3': {
            marginLeft: '12px',
            maxWidth: 'calc(100% - 12px)',
          },
          '@bp4': {
            height: '44px',
          },
          '@bp5': {
            marginLeft: '3px',
            marginRight: '-3px',
          },
          '@bp6': {
            marginLeft: '-3px',
            marginRight: '-3px',
          },
        },
        '@bp4': {
          fontSize: '15px',
        },
        '@bp5': {
          display: 'grid',
          gridTemplateColumns: '4fr 7fr',
          alignItems: 'flex-start',
          marginTop: '18px',
        },
      },
      deepLink: {
        ...primary,
        fontSize: '13px',
        fontFamily: '$body',
        fontWeight: '$bold',
        input: {
          ...primary.input,
          height: '34px',
          marginLeft: 0,
          maxWidth: '100%',
          '@bp5': {
            height: '44px',
          },
        },
        '@bp5': {
          alignItems: 'flex-start',
          fontSize: '15px',
        },
      },
    },
  },
});

const Label = styled('span', {
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
});

const InputType = styled('input', {
  display: 'block',
  fontFamily: '$body',
  padding: '6px 12px',
  fontSize: '16px !important',
  width: '100%',
  variants: {
    view: {
      checkbox: {
        width: 'auto',
      },
    },
  },
  '&:focus-visible': {
    outlineColor: '#101010',
  },
  '@bp3': {
    fontSize: '$size14',
  },
});

const LabelWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  variants: {
    labelSpacedVariant: {
      true: {
        justifyContent: 'space-between',
        '@bp3': {
          justifyContent: 'flex-start',
        },
      },
    },
  },
});

const LabelSpan = styled('span', {
  variants: {
    optional: {
      true: {
        marginRight: '12px',
      },
    },
    view: {
      editForm: {
        marginBottom: '4px',
        lineHeight: 1.4285,
      },
    },
  },
});

const Optional = styled('span', {
  fontSize: '14px',
  fontWeight: '$regular',
  fontFamily: '$body',
  color: '#52545F',
  variants: {
    deepLink: {
      true: {
        fontSize: '13px',
      },
    },
  },
});

const Required = styled('span', {
  marginLeft: '1px',
  variants: {
    asterisk: {
      red: {
        color: '#ff0000',
      },
      bold: {
        fontWeight: '600',
        marginLeft: '12px',
      },
    },
  },
});

const Input: React.FC<Props> = ({
  asterisk,
  value,
  labelSpacedVariant,
  label,
  type,
  name,
  optional = false,
  required = false,
  resourceLink,
  blockInput = false,
  placeholder = '',
  inputArgs = {},
  view,
  inputRef,
  lockValue = false,
  onKeyDown,
  noLabel = false,
  ...props
}) => {
  const [valueState, setValue] = React.useState(value);
  const handleChange = (e): void => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const valueData = (blockInput as boolean) ? value : valueState;

  const input = (
    <InputType
      ref={inputRef}
      type={type}
      view={view as any}
      value={valueData}
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={lockValue}
      onKeyDown={onKeyDown}
      {...inputArgs}
    />
  );

  const labelWrapper = (
    <LabelWrapper labelSpacedVariant={labelSpacedVariant}>
      {label !== undefined && (
        <LabelSpan
          optional={optional}
          view={view as any}
        >
          {label}
        </LabelSpan>
      )}
      {optional && (
        <Optional deepLink={view === 'deepLink'}>(Optional)</Optional>
      )}
      {required && (
        <Tooltips
          required
          content="This field is required."
        >
          <Required
            asterisk={asterisk}
            css={{ marginLeft: '2px' }}
          >
            *
          </Required>
        </Tooltips>
      )}
    </LabelWrapper>
  );

  return (
    <Container
      view={view}
      htmlFor={name}
      as={label === undefined && 'div'}
      {...props}
    >
      {(label !== undefined || optional || required) && !noLabel && (
        <Label>
          {labelWrapper}
          {resourceLink !== undefined && <div>{resourceLink}</div>}
        </Label>
      )}
      {input}
    </Container>
  );
};

export default Input;

import React from 'react';
import './baseIcon.scss';
import { BaseIconProps } from 'src/types/baseComponentTypes';

const BaseIcon: React.FC<BaseIconProps> = ({ icon: Icon, size, className }) => {
  return (
    <span
      className={`baseIcon ${className ? className : ''}`.trim()}
      style={
        {
          ...(size && { '--iconSize': `${size}px` }),
        } as React.CSSProperties
      }
    >
      <Icon />
    </span>
  );
};

export default BaseIcon;

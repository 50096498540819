export const formErrors = {
  mustEnterValidEmailAddress: 'You must enter a valid email address.',
  originalPasswordMissing:
    'You must enter your current password to update the password.',
  missingNewPassword: 'You must confirm the password.',
  mismatchPasswords: 'Passwords Do not match',
  firstNameMissing: 'You must enter First Name.',
  lastNameMissing: 'You must enter Last name.',
  dateOfBirthMissing:
    'You must enter a date of Birth and be over 18 years old.',
  addressMissing: 'You must enter an address.',
  cityMissing: 'You must enter a city.',
  zipCodeMissing: 'You must enter a zip code.',
  phoneMissing: 'You must enter a phone.',
  websiteMissing: 'You must enter a website.',
  businessModelMissing: 'You must enter a business model.',
  productCategoriesMissing: 'You must enter a product category.',
  targetAudienceMissing: 'You must enter a target audience.',
  monthlyVisitorsMissing: 'You must enter a monthly visitors.',
  monthlyPageViews: 'You must enter a month page views.',
  productCategory: 'You must select at least one.',
  passwordLessThanSixChars: 'Password must be at least 6 characters long.',
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from './../../../theme/index';
import Input from './../../atoms/Input/Input';
import Select from './../../atoms/Select/Select';
import Password from './../../molecules/Password/Password';
import Checkbox from './../../atoms/Checkbox/Checkbox';
import BaseInputDate from './../../atoms/BaseInputDate/BaseInputDate';
import DragDropList from './../../molecules/DragDropList/DragDropList';
import { Button } from '../../atoms/Button/Button';
import TermsAndConditionsOfUse from './../../molecules/TermsandConditionsofUse/TermsAndConditionsOfUse';
import Tooltips from './../../atoms/Tooltip/Tooltips';
import { UserContext } from '../../../context/UserContext';
import client from '../../api';
import moment from 'moment';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';
import { countries } from 'src/data/countries';
import {
  businessType,
  callingTime,
  businessModel,
  targetAudience,
  monthlyVisitors,
  monthlyViews,
  categories,
} from 'src/data/userOptions';
import { formErrors } from 'src/data/errorMessages';

const Container = styled('div', {});

const Heading = styled('h2', {
  fontFamily: '$label',
  fontSize: '36px',
  fontWeight: '600',
  letterSpacing: '1px',
  lineHeight: 1.1,
  margin: 0,
  padding: '1px 0 24px',
  color: '$darkgray',
});

const SubHeading = styled('span', {
  display: 'block',
  fontFamily: '$heading',
  fontSize: '17px',
  fontWeight: '500',
  lineHeight: 1.1,
  textTransform: 'uppercase',
  marginBottom: '30px',
  color: '$black',
  padding: '20px 0 17px',
  margin: '53px 0 18px',
  borderBottom: '1px #e4e4e4 dashed',
});

const Form = styled('form');

const InputLabel = styled(Input, {
  fontSize: '12px',
});

const SelectorLabel = styled('label', {
  display: 'block',
  fontFamily: '$label',
  fontSize: '16px',
  fontWeight: '400',
  marginBottom: '20px',
  div: {
    fontSize: '16px',
  },
});

const SpanLabel = styled('label', {
  display: 'block',
  fontSize: '16px',
  fontWeight: '600',
  fontFamily: '$label',
  color: '$darkgray',
  paddingBottom: `4px`,
});

const SaveButton = styled(Button, {
  backgroundColor: '#595F7B',
  color: '$clear',
  textTransform: 'none',
  padding: '15px 22px',
  fontFamily: '$heading',
  fontWeight: '$bold',
  lineHeight: 1,
  letterSpacing: '0.8px',
  width: 'auto',
  '&:hover': {
    backgroundColor: '#484d66',
  },
});

const CancelButton = styled(Button, {
  backgroundColor: '#808080',
  color: '$clear',
  textTransform: 'none',
  padding: '15px 34px 14px 35px',
  marginLeft: '5px',
  fontFamily: '$heading',
  lineHeight: 1,
  width: 'auto',
  letterSpacing: '0.8px',
});

const Assistance = styled('span', {
  display: 'block',
  color: '#7c7c7c',
  fontSize: '12px',
  lineHeight: 1.75,
  letterSpacing: '0.3px',
  fontFamily: '$label',
  marginTop: '-20px',
  marginBottom: '20px',
});

const ButtonSection = styled('div', {
  display: 'flex',
  paddingTop: '20px',
});

const Category = styled('label', {
  marginBottom: '2px',
  display: 'block',
});

const CategoryWrapper = styled('div', {
  maxWidth: 'max-content',
});

const Wrapper = styled('div', {
  marginBottom: '20px',
});

const TooltipChildren = styled('div', {});

const InputStyles = {
  color: '$darkgray',
  margin: '0',
};

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16',
});

const Success = styled('div', {
  fontFamily: '$label',
  color: '#325e1c',
  border: '1px solid #ebccd1',
  borderColor: '#c9e1bd',
  backgroundColor: '#f3faef',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16',
});

const UserEditInfo: React.FC = () => {
  // updating the User Context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userCredentials, updateUserCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState('');
  const [contactError, setContactError] = useState(false);
  const [successMessages, setSuccessMessages] = useState('');
  const navigate = useNavigate();

  const level = window.localStorage.getItem('level');
  let forgotPassFlow = false;
  if (window.localStorage.getItem('forgotPass') !== null) {
    forgotPassFlow = true;
  }

  let isReadOnly = false;
  if (level === 'analyst' || level === 'link_creator') {
    isReadOnly = true;
  }

  const [pageHeader, setPageHeader] = useState('');
  const [additionalDomains, setAdditionalDomains] = useState([]);
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmValue, setConfirmPassword] = useState('');
  const [selectValue, setSelectValue] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [selectCountry, setSelectCountry] = useState('');
  const [selectBestTimeToCall, setSelectBestTimeToCall] = useState([]);
  const [selectBusinessModel, setSelectBusinessModel] = useState([]);
  const [selectTargetAudience, setSelectTargetAudience] = useState([]);
  const [selectMonthlyVisitors, setSelectMonthlyVisitors] = useState([]);
  const [selectMonthlyPageViews, setSelectMonthlyPageViews] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [acceptedTNC, setAcceptedTNC] = useState(false);

  const inputEMail = useRef<HTMLInputElement>(null);
  const inputCurrentPassword = useRef<HTMLInputElement>(null);
  const inputFirstName = useRef<HTMLInputElement>(null);
  const inputLastName = useRef<HTMLInputElement>(null);
  const inputUsername = useRef<HTMLInputElement>(null);
  const inputCompany = useRef<HTMLInputElement>(null);
  const inputAddress = useRef<HTMLInputElement>(null);
  const inputCity = useRef<HTMLInputElement>(null);
  const inputRegion = useRef<HTMLInputElement>(null);
  const inputZipCode = useRef<HTMLInputElement>(null);
  const inputPhone = useRef<HTMLInputElement>(null);
  const inputFax = useRef<HTMLInputElement>(null);
  const inputWebsite = useRef<HTMLInputElement>(null);
  const inputSecondaryWebsite = useRef<HTMLInputElement>(null);
  const inputFacebook = useRef<HTMLInputElement>(null);
  const inputInstagram = useRef<HTMLInputElement>(null);
  const inputTwitter = useRef<HTMLInputElement>(null);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token, logout_token, user } =
    userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .get('/api/user/get/details/full', {
          headers: { csrf_token },
          auth: { username, password },
        })
        .then(({ data }) => {
          // setOldDetails({...data}); // saving original data for compare before submitting the new
          setPageHeader(data.userName);
          inputEMail.current.value = data.email;

          inputFirstName.current.value = data.firstName;
          inputLastName.current.value = data.lastName;

          if (level === 'analyst' || level === 'link_creator') {
            inputUsername.current.value = username;
          }

          if (inputCompany.current !== null) {
            inputCompany.current.value = data.company;
          }
          if (inputAddress.current !== null) {
            inputAddress.current.value = data.address;
          }
          if (inputCity.current !== null) {
            inputCity.current.value = data.city;
          }
          if (inputRegion.current !== null) {
            inputRegion.current.value = data.region;
          }
          if (inputZipCode.current !== null) {
            inputZipCode.current.value = data.zipCode;
          }
          if (inputPhone.current !== null) {
            inputPhone.current.value = data.phone;
          }
          if (inputFax.current !== null) {
            inputFax.current.value = data.fax;
          }
          if (inputWebsite.current !== null) {
            inputWebsite.current.value = data.webSite;
          }
          if (inputSecondaryWebsite.current !== null) {
            inputSecondaryWebsite.current.value = data.secondaryWebsite;
          }
          if (inputFacebook.current !== null) {
            inputFacebook.current.value = data.facebookPage;
          }
          if (inputInstagram.current !== null) {
            inputInstagram.current.value = data.instagramHandle;
          }
          if (inputTwitter.current !== null) {
            inputTwitter.current.value = data.twitterHandle;
          }

          setSelectValue(data.businessType);
          setDateOfBirth(new Date(data.dateOfBirth * 1000));
          setSelectCountry(data.country);
          setSelectBestTimeToCall(data.bestTimeToCall ?? 'Morning');
          setAdditionalDomains(data.additionalDomains);
          setSelectBusinessModel(data.businessModel);
          setProductCategories(data.productCategories);
          setSelectTargetAudience(data.targetAudience);
          setSelectMonthlyVisitors(data.monthlyVisitors);
          setSelectMonthlyPageViews(data.monthlyPageViews);
          setAcceptedTNC(data.termsNCond);
        })
        .catch((e) => {
          console.log('error', e);
          setContactError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, username]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSubmit = (event: {
    preventDefault: () => void;
    target: any[];
  }): void => {
    event.preventDefault();
    setSuccessMessages('');
    setErrorMessages('');
    setContactError(false);

    window.scrollTo(0, 0);

    let newData;
    if (level === 'analyst' || level === 'link creator') {
      newData = {
        email: inputEMail.current?.value,
        inputCurrentPassword: !forgotPassFlow
          ? inputCurrentPassword.current?.value
          : '',
        passwordValue,
        confirmValue,
        firstName: inputFirstName.current?.value,
        lastName: inputLastName.current?.value,
        forgotPass: forgotPassFlow,
      };
    } else {
      newData = {
        email: inputEMail.current?.value,
        inputCurrentPassword: !forgotPassFlow
          ? inputCurrentPassword.current?.value
          : '',
        passwordValue,
        confirmValue,
        firstName: inputFirstName.current?.value,
        lastName: inputLastName.current?.value,
        company: inputCompany.current?.value,
        businessType: selectValue,
        dateOfBirth,
        address: inputAddress?.current?.value,
        city: inputCity.current?.value,
        region: inputRegion.current?.value,
        country: selectCountry,
        forgotPass: forgotPassFlow,
        zipCode: inputZipCode.current?.value,
        phone: inputPhone.current?.value,
        fax: inputFax.current?.value,
        bestTimeToCall: selectBestTimeToCall,
        website: inputWebsite.current?.value,
        additionalDomains,
        businessModel: selectBusinessModel,
        productCategories,
        targetAudience: selectTargetAudience,
        monthlyVisitors: selectMonthlyVisitors,
        monthlyPageViews: selectMonthlyPageViews,
        secondaryWebsite: inputSecondaryWebsite.current?.value,
        facebook: inputFacebook.current?.value,
        instagram: inputInstagram.current?.value,
        twitter: inputTwitter.current?.value,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    if (formValidate(newData) === 1) {
      return 0;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { password, username, csrf_token } = userCredentials;
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      void client
        .post('/api/user/save?_format=json', newData, {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            csrf_token,
          },
          auth: { username, password },
        })
        .then((response) => {
          setSuccessMessages(response.data);

          // updating the localStorage user password
          if (passwordValue !== '') {
            const newUserCredit = JSON.parse(
              window.localStorage.getItem('user_credit'),
            );
            newUserCredit.password = passwordValue;
            window.localStorage.setItem(
              'user_credit',
              JSON.stringify(newUserCredit),
            );

            const newCredentials = {
              password: passwordValue,
              username,
              csrf_token,
              logout_token,
              user,
            };

            updateUserCredentials(newCredentials);
          }

          if (window.localStorage.getItem('forgotPass') !== null) {
            window.localStorage.removeItem('forgotPass');
          }
        })
        .catch((e) => {
          console.log('while saving user: ', e);
          // if (e.response.data.message!==undefined) {
          //   setErrorMessages(e.response.data.message);
          // }
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formValidate = (newData) => {
    const role = window.localStorage.getItem('level'); // user role to set the number of validations

    // validate email
    const isEmailValid = newData.email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    if (isEmailValid === null) {
      setErrorMessages(formErrors.mustEnterValidEmailAddress);
      return 1;
    }

    // validate passwords
    const currentPass = newData.inputCurrentPassword ?? '';
    const newPass = newData.passwordValue ?? '';
    const confirmPass = newData.confirmValue ?? '';

    if (newPass !== '' || confirmPass !== '') {
      // check current password if not in 'forgot password flow'
      if (!forgotPassFlow) {
        if (currentPass === '') {
          setErrorMessages(formErrors.originalPasswordMissing);
          return 1;
        }
      }

      if (confirmPass === '') {
        setErrorMessages(formErrors.missingNewPassword);
        return 1;
      }
      if (newPass !== confirmPass) {
        setErrorMessages(formErrors.mismatchPasswords);
        return 1;
      }
      if (newPass.length < 6) {
        setErrorMessages(formErrors.passwordLessThanSixChars);
        return 1;
      }
    }

    if (
      role === 'administrator' ||
      role === 'account_manager' ||
      role === 'affiliate'
    ) {
      if (newData.firstName === '') {
        setErrorMessages(formErrors.firstNameMissing);
        return 1;
      }
      if (newData.lastName === '') {
        setErrorMessages(formErrors.lastNameMissing);
        return 1;
      }
      if (newData.dateOfBirth === null) {
        setErrorMessages(formErrors.dateOfBirthMissing);
        return 1;
      }
      if (newData.address === '') {
        setErrorMessages(formErrors.addressMissing);
        return 1;
      }
      if (newData.city === '') {
        setErrorMessages(formErrors.cityMissing);
        return 1;
      }
      if (newData.zipCode === '') {
        setErrorMessages(formErrors.zipCodeMissing);
        return 1;
      }
      if (newData.phone === '') {
        setErrorMessages(formErrors.phoneMissing);
        return 1;
      }
      if (newData.website === '') {
        setErrorMessages(formErrors.websiteMissing);
        return 1;
      }
      if (newData.businessModel === '') {
        setErrorMessages(formErrors.businessModelMissing);
        return 1;
      }
      if (newData.targetAudience === '') {
        setErrorMessages(formErrors.targetAudienceMissing);
        return 1;
      }
      if (newData.monthlyVisitors === '') {
        setErrorMessages(formErrors.monthlyVisitorsMissing);
        return 1;
      }
      if (newData.monthlyPageViews === '') {
        setErrorMessages(formErrors.monthlyPageViews);
        return 1;
      }
      if (newData.productCategories.length === 0) {
        setErrorMessages(formErrors.productCategory);
        return 1;
      }
    } // end if role==="administrator" || "account_manager" || "affiliate"
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleProductCategoriesChange = (newCategory) => {
    setProductCategories([...productCategories, newCategory]);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const basicFields = () => {
    return (
      <>
        <InputLabel
          type="text"
          label="First name"
          view="editForm"
          required
          css={{ color: '$darkgray' }}
          inputRef={inputFirstName}
          lockValue={isReadOnly}
        />
        <InputLabel
          type="text"
          label="Last name"
          view="editForm"
          required
          css={{ color: '$darkgray' }}
          inputRef={inputLastName}
          lockValue={isReadOnly}
        />

        {(level === 'analyst' || level === 'link_creator') && (
          <InputLabel
            type="text"
            label="Username"
            view="editForm"
            required
            css={{ color: '$darkgray' }}
            inputRef={inputUsername}
            lockValue={true}
          />
        )}

        <InputLabel
          type="text"
          label="E-mail address"
          view="editForm"
          required
          css={{ color: '$darkgray' }}
          inputRef={inputEMail}
          lockValue={isReadOnly}
        />
        <Assistance>
          A valid e-mail address. All e-mails from the system will be sent to
          this address. The e-mail address is not made public and will only be
          used if you wish to receive a new password or wish to receive certain
          news or notifications by e-mail.
        </Assistance>

        {!forgotPassFlow && (
          <>
            <InputLabel
              type="password"
              label="Current password"
              view="editForm"
              css={{ color: '$darkgray' }}
              value=""
              inputRef={inputCurrentPassword}
            />
            <Assistance>
              Enter your current password to change <i>the E-mail</i> address or{' '}
              <i>Password.</i>{' '}
            </Assistance>
          </>
        )}

        <Tooltips content="To change the current user password, enter the new password in both fields.">
          <div>
            <Password
              confirmPassword={confirmValue}
              password={passwordValue}
              setConfirmPassword={setConfirmPassword}
              setPassword={setPasswordValue}
            />
          </div>
        </Tooltips>
      </>
    );
  };

  const extraFields = (): Element => {
    return (
      <>
        <InputLabel
          type="text"
          label="Company"
          view="editForm"
          css={{ color: '$darkgray' }}
          inputRef={inputCompany}
        />

        <SelectorLabel htmlFor="Business Type">
          <SpanLabel>Business Type</SpanLabel>
          <Select
            isForm
            id="Business Type"
            placeholder="- None -"
            name="Business Type"
            options={businessType}
            value={{ label: selectValue }}
            setValue={(select) => {
              setSelectValue(select.label);
            }}
          />
        </SelectorLabel>

        <SubHeading>User Information</SubHeading>
        <Tooltips content="(Must be over 18)">
          <Wrapper>
            <Tooltips content="E.g., 2023-03-15">
              <TooltipChildren css={InputStyles}>
                <BaseInputDate
                  labelText="Date of Birth"
                  value={dateOfBirth}
                  name="initial-date"
                  callback={(dob) => {
                    setDateOfBirth(moment(dob).format('YYYY-MM-DD'));
                  }}
                />
              </TooltipChildren>
            </Tooltips>
          </Wrapper>
        </Tooltips>

        <Tooltips content="Please enter a valid contact address.">
          <div>
            <InputLabel
              type="text"
              label="Address"
              view="editForm"
              required
              css={{ color: '$darkgray' }}
              inputRef={inputAddress}
            />
          </div>
        </Tooltips>

        <InputLabel
          type="text"
          label="City"
          view="editForm"
          required
          css={{ color: '$darkgray' }}
          inputRef={inputCity}
        />
        <InputLabel
          type="text"
          label="Region"
          view="editForm"
          css={{ color: '$darkgray' }}
          inputRef={inputRegion}
        />

        <SelectorLabel htmlFor="Country">
          <SpanLabel>Country</SpanLabel>
          <Select
            isForm
            id="Country"
            placeholder="- None -"
            options={countries}
            value={{ label: selectCountry }}
            setValue={(select) => {
              setSelectCountry(select.label);
            }}
          />
        </SelectorLabel>

        <Tooltips content="Please enter a valid zipcode.">
          <div>
            <InputLabel
              type="number"
              label="ZipCode"
              view="editForm"
              required
              css={{ color: '$darkgray' }}
              inputRef={inputZipCode}
            />
          </div>
        </Tooltips>

        <Tooltips content="Please enter a valid phone.">
          <div>
            <InputLabel
              type="string"
              label="Phone"
              view="editForm"
              required
              css={{ color: '$darkgray' }}
              inputRef={inputPhone}
            />
          </div>
        </Tooltips>

        <InputLabel
          type="text"
          label="Fax"
          view="editForm"
          css={{ color: '$darkgray' }}
          inputRef={inputFax}
        />

        <SelectorLabel htmlFor="calling Time">
          <SpanLabel>Best time to call</SpanLabel>
          <Select
            isForm
            id="calling Time"
            placeholder="- None -"
            options={callingTime}
            value={{ label: selectBestTimeToCall }}
            setValue={(select) => {
              setSelectBestTimeToCall(select.label);
            }}
          />
        </SelectorLabel>
        <SubHeading>WebSite Details</SubHeading>
        <InputLabel
          type="text"
          label="Website"
          view="editForm"
          required
          css={{ color: '$darkgray' }}
          inputRef={inputWebsite}
        />
        <Wrapper>
          <DragDropList
            title="Additional Domains"
            data={additionalDomains}
            onChange={setAdditionalDomains}
          />
        </Wrapper>
        <SelectorLabel htmlFor="business-model">
          <SpanLabel>Business Model *</SpanLabel>
          <Select
            isForm
            id="business-model"
            placeholder="- None -"
            options={businessModel}
            value={{ label: selectBusinessModel }}
            setValue={(select) => {
              setSelectBusinessModel(select.label);
            }}
          />
        </SelectorLabel>

        <Wrapper>
          <SpanLabel>Product Categories *</SpanLabel>
          {categories.map((category, i) => (
            <CategoryWrapper key={`category-${categories}-${i}`}>
              <Category>
                <Checkbox
                  name={category}
                  type="editForm"
                  isAccept={productCategories.includes(category)}
                  callback={(category) => {
                    handleProductCategoriesChange(category);
                  }}
                >
                  {category}
                </Checkbox>
              </Category>
            </CategoryWrapper>
          ))}
        </Wrapper>

        <SelectorLabel htmlFor="Target Audience">
          <SpanLabel>Target Audience *</SpanLabel>
          <Select
            isForm
            id="Target Audience"
            placeholder="- None -"
            options={targetAudience}
            value={{ label: selectTargetAudience }}
            setValue={(select) => {
              setSelectTargetAudience(select.label);
            }}
          />
        </SelectorLabel>
        <SelectorLabel htmlFor="Monthly Visitors">
          <SpanLabel>Monthly Visitors *</SpanLabel>
          <Select
            isForm
            id="Monthly Visitors"
            placeholder="- None -"
            options={monthlyVisitors}
            value={{ label: selectMonthlyVisitors }}
            setValue={(select) => {
              setSelectMonthlyVisitors(select.label);
            }}
          />
        </SelectorLabel>
        <SelectorLabel htmlFor="Monthly Page Views">
          <SpanLabel>Monthly Page Views *</SpanLabel>
          <Select
            isForm
            id="Monthly Page Views"
            placeholder="- None -"
            options={monthlyViews}
            value={{ label: selectMonthlyPageViews }}
            setValue={(select) => {
              setSelectMonthlyPageViews(select.label);
            }}
          />
        </SelectorLabel>

        <InputLabel
          type="text"
          label="Secondary Website"
          view="editForm"
          css={{ color: '$darkgray' }}
          inputRef={inputSecondaryWebsite}
        />
        <InputLabel
          type="text"
          label="Facebook Page"
          view="editForm"
          css={{ color: '$darkgray' }}
          inputRef={inputFacebook}
        />
        <InputLabel
          type="text"
          label="Instagram Handle"
          view="editForm"
          css={{ color: '$darkgray' }}
          inputRef={inputInstagram}
        />
        <InputLabel
          type="text"
          label="Twitter Handle"
          view="editForm"
          css={{ color: '$darkgray' }}
          inputRef={inputTwitter}
        />
      </>
    );
  };

  return (
    <Container>
      {level !== 'analyst' && level !== 'link_creator' && (
        <>
          <Heading>{pageHeader}</Heading>
          <SubHeading>ACCOUNT DETAILS</SubHeading>
        </>
      )}
      {(level === 'analyst' || level === 'link_creator') && (
        <Heading>ACCOUNT DETAILS</Heading>
      )}

      <Form>
        {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}
        {contactError && <ErrorMessage />}
        {successMessages !== '' ? <Success>{successMessages}</Success> : ''}

        {basicFields()}

        {level === 'affiliate' ? extraFields() : null}

        {level !== 'link_creator' && level !== 'analyst' && (
          <TermsAndConditionsOfUse
            isAccept={acceptedTNC}
            readOnly={true}
          />
        )}

        <ButtonSection>
          <SaveButton onClick={handleSubmit}>Submit</SaveButton>
          {level !== 'link_creator' && level !== 'analyst' ? (
            <CancelButton
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/user/cancel');
              }}
            >
              Cancel account
            </CancelButton>
          ) : null}
        </ButtonSection>

        {(level === 'link_creator' || level === 'analyst') && (
          <TermsAndConditionsOfUse
            isAccept={acceptedTNC}
            readOnly={true}
          />
        )}
      </Form>
    </Container>
  );
};

export default UserEditInfo;

export interface BaseInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface BaseInputDateProps {
  labelText?: string;
  name: string;
  value?: Date;
  callback?: (e: string) => void;
}

export interface BaseLabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
  labelText: string;
  className?: string;
}

export interface DropDownOptions {
  value: string;
  label: string;
}

export interface MultiSelectItem {
  id: number;
  name: string;
}

export interface MultiSelectFilterProps {
  items: MultiSelectItem[];
  selectedItems: MultiSelectItem[];
  onSelectionChange: (selected: MultiSelectItem[]) => void;
  label?: string;
  breakpoint?: string;
  tagLayout?: TagListLayout;
}

export interface MultiSelectFilterDropdownProps {
  items: MultiSelectItem[];
  selectedItems: MultiSelectItem[];
  onSelectionChange: (items: MultiSelectItem[]) => void;
  label?: string;
}

export interface MultiSelectFilterTagsProps {
  selectedItems: MultiSelectItem[];
  handleRemoveTag: (item: MultiSelectItem) => void;
  layoutClass?: string;
}

export enum TagListLayout {
  Carousel = 'carousel',
  Wrap = 'wrap',
}

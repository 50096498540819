import React from 'react';
import { BaseInputProps } from 'src/types/formTypes';
import 'src/components/atoms/BaseInput/baseInput.scss';

const BaseInput: React.FC<BaseInputProps> = ({
  id,
  name,
  value,
  onFocus,
  onChange,
  ...props
}) => (
  <input
    className="baseInput"
    id={id}
    type="date"
    name={name}
    value={value}
    onFocus={onFocus}
    onChange={onChange}
    {...props}
  />
);

export default BaseInput;

import { DropDownOptions } from 'src/types/formTypes';

export const businessType: DropDownOptions[] = [
  { value: 'individual', label: 'Individual' },
  { value: 'company', label: 'Company' },
  { value: 'other', label: 'Other' },
];

export const callingTime: DropDownOptions[] = [
  { value: 'morning', label: 'Morning' },
  { value: 'noon', label: 'Noon' },
  { value: 'evening', label: 'Evening' },
];

export const businessModel: DropDownOptions[] = [
  { value: 'content', label: 'Content' },
  { value: 'blog', label: 'Blog' },
  { value: 'discount', label: 'Discount' },
];

export const targetAudience: DropDownOptions[] = [
  { value: 'women', label: 'Mostly Women' },
  { value: 'men', label: 'Mostly Men' },
  { value: 'both', label: 'Both' },
];

export const monthlyVisitors: DropDownOptions[] = [
  { value: '1', label: 'Up to 1,000' },
  { value: '2', label: '1,000 to 500,000' },
  { value: '3', label: 'More than 500,000' },
];

export const monthlyViews: DropDownOptions[] = [
  { value: '1', label: 'Up to 1,000' },
  { value: '2', label: '1,000 to 500,000' },
  { value: '3', label: 'More than 500,000' },
];

export const categories = [
  'Baby Boomer',
  'Entertainment & Art',
  'Food & Drink',
  'Men',
  'Mom & Family',
  'Pets',
  'Travel',
  'Women',
  'Wedding',
  'Style & Beauty',
  'Home & Decor',
  'Health & Wellness',
  'Baby & Kids',
  'Eco-Friendly',
];

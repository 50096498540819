// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { type ReactNode } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { styled, keyframes, type ThemeProps } from './../../../theme/index';

export interface Props extends ThemeProps {
  content: string | ReactNode;
  children: ReactNode;
  required?: boolean;
}

const slideRightAndFade = keyframes({
  from: {
    opacity: 0,
    transform: 'translateX(5px)',
  },
  to: {
    opacity: 1,
    transform: 'translateX(0)',
  },
});

const ToolTipContent = styled(Tooltip.Content, {
  zIndex: 11,
  fontSize: '15px',
  color: '$clear2',
  backgroundColor: '$black',
  opacity: 0,
  wordBreak: 'break-word',
  maxWidth: '200px',
  padding: '12px 16px',
  transform: 'translateX(5px)',
  animationDuration: '800ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  animationFillMode: 'forwards',
  willChange: 'transform, opacity',
  '&[data-state="delayed-open"][data-side="left"]': {
    animationName: slideRightAndFade,
  },
  variants: {
    required: {
      true: {
        '&[data-state="delayed-open"][data-side="bottom"]': {
          animationName: slideRightAndFade,
        },
      },
    },
  },
});

const Tooltips: React.FC<Props> = ({
  content,
  children,
  required,
  ...props
}) => {
  const sidePosition = (required as boolean) ? 'bottom' : 'left';
  const alignPosition = (required as boolean) ? 'start' : 'center';

  return (
    <Tooltip.Provider
      delayDuration={700}
      skipDelayDuration={300}
    >
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <ToolTipContent
          required={required}
          side={sidePosition}
          align={alignPosition}
          {...props}
        >
          {content}
          <Tooltip.Arrow />
        </ToolTipContent>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default Tooltips;

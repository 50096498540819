import React, { useEffect, useState } from 'react';
import { BaseInputDateProps } from 'src/types/formTypes';
import Calendar from 'src/components/atoms/DatePicker/Calendar';
import BaseInput from 'src/components/atoms/BaseInput/BaseInput';
import BaseLabel from 'src/components/atoms/BaseLabel/BaseLabel';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconCalendar } from 'src/icons/iconCalendar.svg';
import 'src/components/atoms/BaseInputDate/baseInputDate.scss';

function isValidDatePart(
  value: string,
  part: 'year' | 'month' | 'day',
): boolean {
  const num = Number(value);
  if (isNaN(num)) return false;

  switch (part) {
    case 'year':
      return num > 0;
    case 'month':
      return num >= 1 && num <= 12;
    case 'day':
      return num >= 1 && num <= 31;
    default:
      return false;
  }
}

const BaseInputDate: React.FC<BaseInputDateProps> = ({
  labelText = '',
  name,
  value = '',
  callback,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [output, setOutput] = useState(value ? new Date(value) : new Date());
  const [rawInput, setRawInput] = useState('');

  const handleFocus = (): void => {
    if (navigator?.userAgentData?.mobile === false) {
      setShowCalendar(true);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLLabelElement>): void => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    if (!(value instanceof Date)) return;

    const utcDate = new Date(
      Date.UTC(
        value.getUTCFullYear(),
        value.getUTCMonth(),
        value.getUTCDate(),
        12,
        0,
        0,
      ),
    );
    setOutput(utcDate);
    setRawInput(utcDate.toISOString().split('T')[0]);
  }, [value]);

  const handleSelection = (nextValue: Date): void => {
    const utcDate = new Date(
      Date.UTC(
        nextValue.getUTCFullYear(),
        nextValue.getUTCMonth(),
        nextValue.getUTCDate(),
        12,
        0,
        0,
      ),
    );
    callback?.(utcDate.toISOString());
    setOutput(utcDate);
    setRawInput(utcDate.toISOString().split('T')[0]);
    if (navigator?.userAgentData?.mobile === false) {
      setShowCalendar(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e.target.value;
    setRawInput(input);

    if (input.length === 10) {
      const [year, month, day] = input.split('-').map(Number);

      if (
        isValidDatePart(String(month), 'month') &&
        isValidDatePart(String(day), 'day') &&
        isValidDatePart(String(year), 'year')
      ) {
        const utcDate = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));

        if (!isNaN(utcDate.getTime())) {
          setOutput(utcDate);
          callback?.(utcDate.toISOString());
        }
      }
    }
  };

  return (
    <BaseLabel
      className={'baseInputDate'}
      labelText={labelText}
      htmlFor={`date-picker-${name}`}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {showCalendar && (
        <Calendar
          className="baseInputDate__calendar"
          callback={handleSelection}
          value={output}
          calendarProps={{
            prev2Label: null,
            next2Label: null,
            prevLabel: '«',
            nextLabel: '»',
            minDetail: 'decade',
          }}
        />
      )}
      <BaseInput
        id={`date-picker-${name}`}
        type="date"
        name={name}
        value={rawInput}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <BaseIcon
        className="baseInputDate__icon"
        icon={IconCalendar}
        size={36}
      />
    </BaseLabel>
  );
};

export default BaseInputDate;

import React from 'react';
import { BaseLabelProps } from 'src/types/formTypes';
import 'src/components/atoms/BaseLabel/baseLabel.scss';

const BaseLabel: React.FC<BaseLabelProps> = ({
  labelText,
  children,
  className,
  ...props
}) => {
  return (
    <label
      className={`baseLabel ${className?.trim()}`}
      {...props}
    >
      {labelText}
      {children}
    </label>
  );
};

export default BaseLabel;
